import React from "react"
import { graphql } from "gatsby"
import { Seo, Layout, Contact, Download, Container, RoundedBox } from "../../components"
import { WhatsappIcon, TelegramIcon } from "../../assets/vectors"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from './style.module.scss'

const ContactTemplate = ({ data, pageContext }) => {
  const { contactFields, seoMetaFields } = data.page
	const { sectionDownload } = data.downloadPropsHomepage.homePageFields

  const metadata = seoMetaFields.wpFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const hero = contactFields.headerSection.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const form = contactFields.formSection.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const download = contactFields.downloadSection.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
	const downloadHomeProps = sectionDownload.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const thanks = data.thanksMessage.contactFields.thanksFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

	const downloadButtons = downloadHomeProps.buttons
	const downloadMockup = downloadHomeProps.mockup?.localFile.childImageSharp.gatsbyImageData.images.fallback

  return (
    <Layout>
      <Seo title={metadata.seoTitle} description={metadata.seoDescription} />

			<section className={styles.contact}>
				<RoundedBox
					className={styles.roundedBox}
					transform="translateX(-50%) rotate(-45deg)"
					width="656.94px"
					height="656.94px"
					position={{
						left: "50%",
						top: "168px"
					}}
				/>

				<Container>
					<div className={styles.contact__head}>
						<h1 className={styles.title}>{hero.heading}</h1>
						<h2 className={styles.subtitle}>{hero.subheading}</h2>
					</div>
					<div className={styles.contact__body}>
						<div className={`${styles.hero} flex`}>
							<div className={styles.image__group}>
								{hero.contentBlock.images.top &&
									<GatsbyImage
										image={hero.contentBlock.images.top.localFile.childImageSharp.gatsbyImageData}
										alt="chat"
									/>
								}
								{hero.contentBlock.images.bottom &&
									<GatsbyImage
										className={styles.image__bottom}
										image={hero.contentBlock.images.bottom.localFile.childImageSharp.gatsbyImageData}
										alt="chat"
									/>
								}
							</div>
							<div className={styles.content__group}>
								<p className={styles.title}>{hero.contentBlock.heading}</p>
								<p className={styles.phone}>{hero.contentBlock.phone}</p>
								<div className={styles.injectedText} dangerouslySetInnerHTML={{__html: hero.contentBlock.content}}></div>

								<div className={styles.social}>
									<a href={hero.contentBlock.links.whatsapp} target="_blank" rel="noopener">
										<WhatsappIcon />
									</a>
									<a href={hero.contentBlock.links.telegram} target="_blank" rel="noopener">
										<TelegramIcon />
									</a>
								</div>
							</div>
						</div>

						<div className={`${styles.support__content} flex`}>
							<div className={styles.content}>
								<p className={styles.title}>{form.contentBlock.support.heading}</p>
								<div className={styles.text} dangerouslySetInnerHTML={{__html: form.contentBlock.support.content}}></div>
							</div>
							<div className={styles.content}>
								<p className={styles.title}>{form.contentBlock.feedback.heading}</p>
								<div className={styles.text} dangerouslySetInnerHTML={{__html: form.contentBlock.feedback.content}}></div>
							</div>
						</div>

						<Contact data={form} lang={pageContext.lang} thanksMessage={thanks}></Contact>

						<div className={styles.download}>
							<Download title={download.heading} buttons={downloadButtons} mockup={downloadMockup}/>
						</div>
					</div>
				</Container>
			</section>
    </Layout>
  )
}

export const Query = graphql`query ContactTemplate {
  page: wpPage(slug: {eq: "contact"}) {
    id
    seoMetaFields {
      wpFields {
        languageValue {
          slug
          name
        }
        seoTitle
        seoDescription
      }
    }
    contactFields {
      headerSection {
        languageValue {
          slug
          name
        }
        heading
        subheading
        contentBlock {
          images {
            top {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            bottom {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
          heading
          phone
          content
          links {
            telegram
            whatsapp
          }
        }
      }
      formSection {
        languageValue {
          slug
          name
        }
        contentBlock {
          support {
            heading
            content
          }
          feedback {
            heading
            content
          }
        }
        formLabels {
          firstName
          lastName
          email
          phone
          country
          subject
          message
          button
        }
      }
      downloadSection {
        languageValue {
          slug
          name
        }
        heading
      }
    }
  }
	downloadPropsHomepage: wpPage(slug: { eq: "home" }) {
		homePageFields {
			sectionDownload {
				languageValue {
					slug
					name
				}
				heading
				subheading
				buttons {
					appleLink
					appleImage {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: NONE
									layout: FULL_WIDTH
								)
							}
						}
					}
					androidLink
					androidImage {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: NONE
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
				mockup {
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: NONE
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
	}
	thanksMessage: wpPage(slug: { eq: "contact" }) {
    id
    contactFields {
      thanksFields {
        languageValue {
          slug
          name
        }
        thanksHeading
        thanksCopy
      }
    }
  }
}
`

export default ContactTemplate
