// extracted by mini-css-extract-plugin
export var contact = "style-module--contact--3f-cp";
export var contact__head = "style-module--contact__head--1oj9s";
export var title = "style-module--title--2LNZu";
export var subtitle = "style-module--subtitle--3O4bp";
export var contact__body = "style-module--contact__body--1ST_u";
export var hero = "style-module--hero--9Nt_Y";
export var image__group = "style-module--image__group--36csg";
export var image__bottom = "style-module--image__bottom--EIvKE";
export var content__group = "style-module--content__group--xKcFt";
export var phone = "style-module--phone--3aJt6";
export var injectedText = "style-module--injected-text--T81N-";
export var social = "style-module--social--v_6gQ";
export var support__content = "style-module--support__content--2VO1H";
export var content = "style-module--content--zpArU";
export var text = "style-module--text--uAKPo";
export var download = "style-module--download--QA64T";
export var roundedBox = "style-module--roundedBox--302DG";